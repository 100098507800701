import { Container } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { HomeTokenAd } from './HomeTokenAd';
import styles from './HomeTokenAd.module.scss';

export const HomeTokenAdBanner = ({ tokenAdsView }: { tokenAdsView: TokenAdsView }) => {
  return (
    <Container className={styles.container}>
      <HomeTokenAd tokenAdsView={tokenAdsView} />
    </Container>
  );
};
