import Link from 'next/link';
import clsx from 'clsx';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import remCalc from '@/helpers/utils/remCalc';
import { ERoutePath } from '@/utils/ERoutePath';
import { faChartLine, faFireFlameCurved, faWavePulse, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HomePageTabs.module.scss';

const NavLink = ({
  href,
  icon,
  label,
  isActive,
}: {
  href: string;
  icon: IconDefinition;
  label: React.ReactNode;
  isActive: boolean;
}) => {
  return (
    <Link href={href} className={clsx(styles.tabLink, { [styles.active]: isActive })}>
      <span className={styles.tabTitle}>
        <FontAwesomeIcon icon={icon} style={{ width: remCalc('16px'), height: remCalc('16px') }} />
        {label}
      </span>
    </Link>
  );
};

export const HomePageTabs = ({ tabId }: { tabId: EHomePageView }) => {
  return (
    <div className={styles.tabs}>
      <NavLink
        href={ERoutePath.home}
        icon={faFireFlameCurved}
        label={EHomePageView.TRENDING}
        isActive={tabId === EHomePageView.TRENDING}
      />
      <NavLink
        href={ERoutePath.topTokensMarketCap}
        icon={faChartLine}
        label={
          <span>
            <span className={styles.mobileNone}>Top By</span> Market Cap
          </span>
        }
        isActive={tabId === EHomePageView.TOP_BY_MARKET_CAP}
      />
      <NavLink
        href={ERoutePath.newCoins}
        icon={faWavePulse}
        label={EHomePageView.LATEST_COINS}
        isActive={tabId === EHomePageView.LATEST_COINS}
      />
    </div>
  );
};
