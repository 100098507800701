import Link from 'next/link';
import clsx from 'clsx';
import { Flex } from 'moralis-ui';
import styles from './ChainsLinkList.module.scss';

const NavLink = ({
  href,
  icon,
  label,
  isActive,
  onChainChange,
}: {
  href: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  isActive: boolean;
  onChainChange: () => void;
}) => {
  return (
    <Link
      href={href}
      className={clsx(styles.tabLink, { [styles.active]: isActive })}
      // ToDo: remove the implementation of link generation and navigation through state
      // A temporary solution due to functionality inconsistency in two places
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) {
          return;
        }
        e.preventDefault();
        onChainChange();
      }}
    >
      <span className={styles.tabLinkIcon}>{icon}</span>
      {label}
    </Link>
  );
};

export const ChainsLinkList = ({
  options,
  value,
  onChainChange,
  generateHref,
}: {
  options: Array<{ id: string; label: string; prefix: React.ReactNode }>;
  value: string | null;
  onChainChange: (chainId: string) => void;
  generateHref: (chainId: string) => string;
}) => {
  return (
    <div className={styles.chainSelector}>
      <Flex shrink={0} spacing={8} align="align-center">
        {options.map((chain) => {
          const href = generateHref(chain.id);

          return (
            <NavLink
              key={`${chain.id}-${chain.label}`}
              href={href}
              icon={chain.prefix}
              label={chain.label}
              isActive={value === chain.id}
              onChainChange={() => onChainChange(chain.id)}
            />
          );
        })}
      </Flex>
    </div>
  );
};
