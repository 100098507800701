import create from 'zustand';
import { logApp } from '@/utils/logApp';

const log = logApp.create('useDrawerStore');
export const useDrawerStore = create<{
  clearDrawerContent: () => void;
  content: JSX.Element | JSX.Element[] | null;
  isVisible: boolean;
  setDrawerContent: (content: JSX.Element | JSX.Element[]) => void;
  showDrawerContent: (content: JSX.Element | JSX.Element[]) => void;
  setDrawerVisibility: (state: boolean) => void;
  showDrawer: () => void;
  hideDrawer: () => void;
  toggleDrawer: () => void;
}>((set, get) => ({
  content: null,
  isVisible: false,

  clearDrawerContent: () => {
    log.debug('clearDrawerContent', { isVisible: get().isVisible });
    set({ isVisible: false, content: null });
  },

  setDrawerContent: (newContent: JSX.Element | JSX.Element[]) => {
    log.debug('setDrawerContent', { isVisible: get().isVisible });
    set({ content: newContent });
  },

  showDrawerContent: (newContent: JSX.Element | JSX.Element[]) => {
    log.debug('showDrawerContent', { isVisible: get().isVisible });
    set({ content: newContent, isVisible: true });
  },

  setDrawerVisibility: (isVisibleArg: boolean) => {
    const isVisible = get().content === null ? false : isVisibleArg;
    log.debug('setDrawerVisibility', { isVisible, isVisibleArg });
    set({ isVisible });
  },

  showDrawer: () => get().setDrawerVisibility(true),
  hideDrawer: () => get().setDrawerVisibility(false),
  toggleDrawer: () => get().setDrawerVisibility(!get().isVisible),
}));
